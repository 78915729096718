import * as Sentry from '@sentry/react'
import { ErrorBoundaryProps } from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import ErrorWithRetry, { ErrorWithRetryProps } from './ErrorWithRetry.tsx'

type FallbackProps = {
  errorData: { error: unknown; componentStack: string; eventId: string; resetError(): void }
} & Partial<Omit<ErrorWithRetryProps, 'retry'>>

const Fallback = ({ errorData: { resetError }, primaryText, secondaryText, ...props }: FallbackProps) => {
  const { t } = useTranslation()

  return (
    <ErrorWithRetry
      primaryText={primaryText ?? t('Oh no! Something went wrong.')}
      secondaryText={secondaryText ?? t('Please try again using the button below.')}
      retry={resetError}
      {...props}
    />
  )
}

export type ErrorBoundaryContentProps = Omit<ErrorBoundaryProps, 'fallback'> & {
  fallbackProps?: Partial<Omit<ErrorWithRetryProps, 'retry'>>
}

/**
 * Enhanced error boundary component that combines React error handling with Sentry tracking
 * and Tanstack Query state management.
 *
 * - Catches and displays React rendering errors.
 * - Reports errors to Sentry for monitoring.
 * - Automatically resets Tanstack Query state on retry.
 */
export const IntegratedErrorBoundary = ({ onReset, fallbackProps, ...props }: ErrorBoundaryContentProps) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <Sentry.ErrorBoundary
        onReset={(...args) => {
          onReset?.(...args)
          reset()
        }}
        fallback={(errorData) => <Fallback errorData={errorData} {...fallbackProps} />}
        {...props}
      />
    )}
  </QueryErrorResetBoundary>
)
