import { Trans, useTranslation } from 'react-i18next'
import { FormControl, FormField, FormItem, FormLabel, FormMessage, useFormContext } from '@opoint/infomedia-storybook'
import { useOpointUser } from '@opoint/authjs-react'
import { useMemo } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { getDefaultFolderNameProfiles } from '../../../selectors/settingsSelectors.ts'
import { useFoldersList } from '../../../api/folders/folders.ts'
import { getUserOwnedProfileFolders } from '../../../helpers/folders.ts'
import SelectField from '../select/Select.tsx'
import { BasicProfileSettingsFormValues } from './BasicProfileSettingsForm.tsx'

const ProfileFolderFormInput = () => {
  const { t } = useTranslation()
  const user = useOpointUser()

  const defaultFolderNameProfiles = useAppSelector(getDefaultFolderNameProfiles)

  const { setValue } = useFormContext<BasicProfileSettingsFormValues>()

  const { data: folders = [] } = useFoldersList()

  const folderOptions = useMemo(() => {
    if (!user?.user_id) {
      return []
    }

    return getUserOwnedProfileFolders(folders, user.user_id)
      .map((folder) => (folder.name === defaultFolderNameProfiles ? { ...folder, name: t(folder.name) } : folder))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [defaultFolderNameProfiles, folders, t, user?.user_id])

  return (
    <div className="flex w-full flex-col gap-1.5">
      <FormField
        name="folder"
        render={({ field: { value, onChange } }) => (
          <FormItem>
            <FormLabel>
              <Trans>Folder</Trans>
            </FormLabel>
            <FormControl>
              <SelectField
                defaultValue={0}
                // @ts-expect-error - The input prop is typed for redux-form, not for react-hook-form.
                input={{
                  value,
                  onChange: (value) => {
                    onChange(parseInt(value, 10))
                    setValue('parent', 0)
                  },
                }}
                options={folderOptions}
                placeholder={folderOptions.length ? t('Select') : `${t('Loading')}...`}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}

export default ProfileFolderFormInput
