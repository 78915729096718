import { useCallback, useMemo } from 'react'
import { Loader, useToast } from '@opoint/infomedia-storybook'
import { useTranslation } from 'react-i18next'
import { useOpointUser } from '@opoint/authjs-react'
import {
  getProfilesRetrieveQueryKey,
  useProfilesPartialUpdate,
  useProfilesRetrieve,
} from '../../../api/profiles/profiles.ts'
import { useAppDispatch } from '../../hooks/useAppDispatch.ts'
import { queryClient } from '../../../queryClient.ts'
import { useFoldersList } from '../../../api/folders/folders.ts'
import { getUserOwnedProfileFolders } from '../../../helpers/folders.ts'
import { BasicProfileSettingsForm, BasicProfileSettingsFormValues } from './BasicProfileSettingsForm.tsx'

type Props = {
  profileId: number
}

export const EditBasicProfileSettings = ({ profileId }: Props) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const user = useOpointUser()
  const dispatch = useAppDispatch()

  const { data: profileData } = useProfilesRetrieve(
    profileId,
    { with_query: true },
    {
      query: {
        useErrorBoundary: true,
      },
    },
  )

  const { data: folders } = useFoldersList({
    query: {
      useErrorBoundary: true,
    },
  })

  const { mutateAsync: updateProfile } = useProfilesPartialUpdate({
    mutation: {
      useErrorBoundary: true,
      onSuccess: (_, { idList }) => {
        // Update the profiles list on success
        dispatch({ type: 'PROFILES_FETCH' })
        toast({
          title: t('Profile was successfully saved'),
          variant: 'success',
        })

        void queryClient.refetchQueries({
          queryKey: getProfilesRetrieveQueryKey(idList),
        })
      },
      onError: () => {
        toast({
          title: t('We were unable to save this profile'),
          variant: 'destructive',
        })
      },
    },
  })

  const handleSubmit = useCallback(
    (formValues: BasicProfileSettingsFormValues) => {
      return updateProfile({
        idList: profileId,
        data: {
          name: formValues.name,
          parent: formValues.parent,
          folder: formValues.folder,
          // @ts-expect-error: Incorrect API schema
          blessed: formValues.blessed,
          trend_interval: formValues.trend_interval,
        },
      })
    },
    [updateProfile, profileId],
  )

  // @ts-expect-error: Incorrect API schema
  const defaultValues = useMemo<BasicProfileSettingsFormValues | undefined>(() => {
    if (!profileData || !folders || !user?.user_id) {
      return
    }

    const defaultFolderId = getUserOwnedProfileFolders(folders, user.user_id).find(({ category }) => category === 0)?.id

    if (!defaultFolderId) {
      throw new Error('No default folder found')
    }

    return {
      name: profileData.name,
      parent: profileData.parent ?? 0,
      folder: profileData.folder ?? defaultFolderId,
      blessed: profileData.blessed ?? false,
      stored_search: profileData.stored_search,
      trend_interval: profileData.trend_interval ?? 0,
    }
  }, [folders, profileData, user?.user_id])

  if (!defaultValues) {
    return (
      <div className="flex h-full min-h-40 items-center justify-center">
        <Loader />
      </div>
    )
  }

  // @ts-expect-error: Incorrect API schema
  return <BasicProfileSettingsForm defaultValues={defaultValues} onSubmit={handleSubmit} />
}
