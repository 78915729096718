import { TagColor } from '@opoint/infomedia-storybook'
import { CheckedState } from '@radix-ui/react-checkbox'
import { addYears, addMonths, addDays, differenceInSeconds } from 'date-fns'
import {
  License,
  SiteOptionsPatchRequestSummarizerParam,
  SourceOptionsPatchRequest,
  SummarizerParam,
  SummarizerParamField,
} from '../../api/opoint.schemas.ts'
import {
  Age,
  LicenseTableRowData,
  SummarizerParamFieldFrontend,
  SummarizerParamFieldTypeFrontend,
  SummarizerParamFrontend,
  LicenseOptions,
  TimeFrameValues,
  ToggleOptions,
} from './types.ts'

const summarizerParamDefaultValue = {
  searchSettings: {
    type: { label: 'None', value: 0 },
    numberOfSentences: '0',
    maxPercentageOfCharactersToShow: '0',
    maxNumberOfWordsToShow: '0',
  },
  reportSettings: {
    type: { label: 'None', value: 0 },
    numberOfSentences: '0',
    maxPercentageOfCharactersToShow: '0',
    maxNumberOfWordsToShow: '0',
  },
  mailSettings: {
    type: { label: 'None', value: 0 },
    numberOfSentences: '0',
    maxPercentageOfCharactersToShow: '0',
    maxNumberOfWordsToShow: '0',
  },
  shareSettings: {
    type: { label: 'None', value: 0 },
    numberOfSentences: '0',
    maxPercentageOfCharactersToShow: '0',
    maxNumberOfWordsToShow: '0',
  },
  followLicense: {
    type: false,
    numberOfSentences: false,
    maxPercentageOfCharactersToShow: false,
    maxNumberOfWordsToShow: false,
  },
}

export const summarizerParamToSummarizerParamFrontend = (
  summarizerParam: SummarizerParam | null,
): SummarizerParamFrontend => {
  if (!summarizerParam) {
    return summarizerParamDefaultValue
  } else {
    return {
      searchSettings: {
        type: assignLabelToSummarizerParamType(summarizerParam.license.type),
        numberOfSentences: summarizerParam.license.number_of_sentences.toString(),
        maxPercentageOfCharactersToShow: summarizerParam.license.max_percentage_of_characters_to_show.toString(),
        maxNumberOfWordsToShow: summarizerParam.license.max_number_of_words_to_show.toString(),
      },
      reportSettings: {
        type: assignLabelToSummarizerParamType(summarizerParam.report_license.type),
        numberOfSentences: summarizerParam.report_license.number_of_sentences.toString(),
        maxPercentageOfCharactersToShow: summarizerParam.report_license.max_percentage_of_characters_to_show.toString(),

        maxNumberOfWordsToShow: summarizerParam.report_license.max_number_of_words_to_show.toString(),
      },
      mailSettings: {
        type: assignLabelToSummarizerParamType(summarizerParam.mail_license.type),
        numberOfSentences: summarizerParam.mail_license.number_of_sentences.toString(),

        maxPercentageOfCharactersToShow: summarizerParam.mail_license.max_percentage_of_characters_to_show.toString(),

        maxNumberOfWordsToShow: summarizerParam.mail_license.max_number_of_words_to_show.toString(),
      },
      shareSettings: {
        type: assignLabelToSummarizerParamType(summarizerParam.share_license.type),
        numberOfSentences: summarizerParam.share_license.number_of_sentences.toString(),

        maxPercentageOfCharactersToShow: summarizerParam.share_license.max_percentage_of_characters_to_show.toString(),

        maxNumberOfWordsToShow: summarizerParam.share_license.max_number_of_words_to_show.toString(),
      },
      followLicense: {
        type: determineIfFollowLicenseIsChecked(summarizerParam),
        numberOfSentences: determineIfFollowLicenseIsChecked(summarizerParam),
        maxPercentageOfCharactersToShow: determineIfFollowLicenseIsChecked(summarizerParam),
        maxNumberOfWordsToShow: determineIfFollowLicenseIsChecked(summarizerParam),
      },
    }
  }
}

export const enum Seconds {
  Year = 31536000, // 365 * 24 * 3600
  Month = 2592000, // 30 * 24 * 3600
  Day = 86400, // 24 * 60 * 60
}

export const getLicenseToggleValue = (licenseValue: number, licenseSetting: number, licenseEnforceSetting: number) => {
  if (licenseEnforceSetting & licenseValue) {
    return ToggleOptions.Enforced
  } else if (licenseValue & licenseSetting) {
    return ToggleOptions.Checked
  } else {
    return ToggleOptions.Unchecked
  }
}

export const licensesToLicenseTableRows = (
  licenses: License[],
  licenseOptions: LicenseOptions,
): LicenseTableRowData[] => {
  return licenses.map((license): LicenseTableRowData => {
    const searchSetting = getLicenseToggleValue(license.value, licenseOptions.license, licenseOptions.licenseEnforce)
    const reportSetting = getLicenseToggleValue(
      license.value,
      licenseOptions.licenseReport,
      licenseOptions.licenseEnforceReport,
    )
    const mailSetting = getLicenseToggleValue(
      license.value,
      licenseOptions.licenseMail,
      licenseOptions.licenseEnforceMail,
    )
    const shareSetting = getLicenseToggleValue(
      license.value,
      licenseOptions.licenseShare,
      licenseOptions.licenseEnforceShare,
    )
    const allCheckBox = determineAllCheckBoxState([searchSetting, reportSetting, mailSetting, shareSetting])

    return {
      name: license.name,
      value: license.value,
      searchSetting: searchSetting,
      reportSetting: reportSetting,
      mailSetting: mailSetting,
      shareSetting: shareSetting,
      allCheckBox: allCheckBox,
    }
  })
}

export const assignLabelToSummarizerParamType = (typeNumber: number): SummarizerParamFieldTypeFrontend => {
  if (typeNumber === 1) return { label: 'Extract', value: typeNumber }
  if (typeNumber === 2) return { label: 'Summary', value: typeNumber }
  return { label: 'None', value: 0 }
}

export const getFirstFeasibleTimeFrameFromSeconds = (seconds: number | null): Age => {
  if (!seconds) {
    return {
      value: null,
      timeFrame: TimeFrameValues.Days,
    }
  }
  if (seconds % Seconds.Year === 0) {
    return {
      value: seconds / Seconds.Year,
      timeFrame: TimeFrameValues.Years,
    }
  }
  if (seconds % Seconds.Month === 0) {
    return {
      value: seconds / Seconds.Month,
      timeFrame: TimeFrameValues.Months,
    }
  }
  if (seconds % Seconds.Day === 0) {
    return {
      value: seconds / Seconds.Day,
      timeFrame: TimeFrameValues.Days,
    }
  }
  return {
    value: seconds,
    timeFrame: TimeFrameValues.Seconds,
  }
}
export const getSecondsFromTimeFrame = (age: Age): number | null => {
  if (!age.value) {
    return null
  }
  const now = new Date()
  let futureDate = now

  if (age.timeFrame === TimeFrameValues.Years) {
    futureDate = addYears(now, age.value)
  } else if (age.timeFrame === TimeFrameValues.Months) {
    futureDate = addMonths(now, age.value)
  } else if (age.timeFrame === TimeFrameValues.Days) {
    futureDate = addDays(now, age.value)
  } else {
    return age.value
  }

  return differenceInSeconds(futureDate, now)
}

export const determineIfFollowLicenseIsChecked = (summarizerParam: SummarizerParam): boolean => {
  const stringifyLicense = JSON.stringify(summarizerParam.license)
  const stringifyReportLicense = JSON.stringify(summarizerParam.report_license)
  const stringifyMailLicense = JSON.stringify(summarizerParam.mail_license)
  const stringifyShareLicense = JSON.stringify(summarizerParam.share_license)
  return (
    stringifyLicense === stringifyReportLicense &&
    stringifyReportLicense === stringifyMailLicense &&
    stringifyMailLicense === stringifyShareLicense
  )
}

export const determineAllCheckBoxState = (toggles: ToggleOptions[]): CheckedState => {
  const allEnforced = toggles.every((value) => value === ToggleOptions.Enforced)
  const allChecked = toggles.every((value) => value === ToggleOptions.Checked)
  const someEnforcedOrChecked = toggles.some(
    (value) => value === ToggleOptions.Enforced || value === ToggleOptions.Checked,
  )
  const indeterminateState = someEnforcedOrChecked && !allChecked && !allEnforced

  if (indeterminateState) {
    return 'indeterminate'
  }

  if (!someEnforcedOrChecked) {
    return false
  }

  return allEnforced || allChecked
}

export const determineCheckBoxColor = (licenseTableRow: LicenseTableRowData): TagColor | undefined => {
  const toggles = [
    licenseTableRow.searchSetting,
    licenseTableRow.reportSetting,
    licenseTableRow.mailSetting,
    licenseTableRow.shareSetting,
  ]
  const allEnforced = toggles.every((value) => value === ToggleOptions.Enforced)
  if (allEnforced) {
    return TagColor.ORANGE
  }
  return undefined
}

export const transformLicenseRowDataToLicenseValues = (licenses: LicenseTableRowData[]): SourceOptionsPatchRequest => {
  // License is written with a "c" in the Source table
  const licenseValues = {
    licence: 0,
    licence_share: 0,
    licence_mail: 0,
    licence_report: 0,
    licence_enforce: 0,
    licence_enforce_share: 0,
    licence_enforce_mail: 0,
    licence_enforce_report: 0,
  }

  licenses.map((license) => {
    if (license.searchSetting === ToggleOptions.Enforced) {
      licenseValues.licence_enforce += license.value
    } else if (license.searchSetting === ToggleOptions.Checked) {
      licenseValues.licence += license.value
    }
    if (license.reportSetting === ToggleOptions.Enforced) {
      licenseValues.licence_enforce_report += license.value
    } else if (license.reportSetting === ToggleOptions.Checked) {
      licenseValues.licence_report += license.value
    }
    if (license.mailSetting === ToggleOptions.Enforced) {
      licenseValues.licence_enforce_mail += license.value
    } else if (license.mailSetting === ToggleOptions.Checked) {
      licenseValues.licence_mail += license.value
    }
    if (license.shareSetting === ToggleOptions.Enforced) {
      licenseValues.licence_enforce_share += license.value
    } else if (license.shareSetting === ToggleOptions.Checked) {
      licenseValues.licence_share += license.value
    }
  })

  return licenseValues
}

const transformSummarizerParamRowFrontendToBackend = (
  summarizerParamLicenseType: SummarizerParamFieldFrontend | null,
): SummarizerParamField => {
  return {
    type: summarizerParamLicenseType?.type ? summarizerParamLicenseType.type.value : 0,
    number_of_sentences: summarizerParamLicenseType?.numberOfSentences
      ? parseInt(summarizerParamLicenseType.numberOfSentences, 10)
      : 0,
    max_percentage_of_characters_to_show: summarizerParamLicenseType?.maxPercentageOfCharactersToShow
      ? parseInt(summarizerParamLicenseType.maxPercentageOfCharactersToShow, 10)
      : 0,
    max_number_of_words_to_show: summarizerParamLicenseType?.maxNumberOfWordsToShow
      ? parseInt(summarizerParamLicenseType.maxNumberOfWordsToShow, 10)
      : 0,
  }
}

export const transformSummarizerParamFrontendToBackend = (
  summarizerParam: SummarizerParamFrontend | null,
): SiteOptionsPatchRequestSummarizerParam => {
  if (summarizerParam) {
    return {
      license: transformSummarizerParamRowFrontendToBackend(summarizerParam.searchSettings),
      report_license: transformSummarizerParamRowFrontendToBackend(summarizerParam.reportSettings),
      mail_license: transformSummarizerParamRowFrontendToBackend(summarizerParam.mailSettings),
      share_license: transformSummarizerParamRowFrontendToBackend(summarizerParam.shareSettings),
    }
  }
  return null
}
