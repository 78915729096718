import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Switch,
} from '@opoint/infomedia-storybook'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Save24Regular } from '@fluentui/react-icons'
import { t } from 'i18next'
import useIsUserAdmin from '../../hooks/useIsUserAdmin.ts'
import { TagDetailStoredSearch } from '../../../api/opoint.schemas.ts'
import { Seconds } from '../../licenses/utils.tsx'
import { ProfileParentFormField } from './ProfileParentFormField.tsx'
import ProfileFolderFormInput from './ProfileFolderFormInput.tsx'

export type BasicProfileSettingsFormValues = {
  name: string
  parent: number
  folder: number
  blessed: boolean
  stored_search?: TagDetailStoredSearch
  trend_interval: number
}

type Props = {
  defaultValues: BasicProfileSettingsFormValues
  onSubmit: (data: BasicProfileSettingsFormValues) => Promise<void>
  submitText?: string
}

type SelectOption = { value: number; label: string }

const intervalOptions: SelectOption[] = [
  { value: -1, label: t('Disable trends') },
  { value: 0, label: t('Automatic') },
  { value: Seconds.Day, label: t('1 day') },
  { value: Seconds.Day * 3, label: t('3 days') },
  { value: Seconds.Day * 7, label: t('1 week') },
  { value: Seconds.Day * 14, label: t('2 weeks') },
  { value: Seconds.Month, label: t('30 days') },
]

const findLabelToValue = (selectedValue: string) => {
  const selectedOption = intervalOptions.find((option) => String(option.value) === selectedValue)
  return selectedOption ? selectedOption.label : t(`Unknown Value`) + ': ' + selectedValue
}

export const BasicProfileSettingsForm = ({ onSubmit, defaultValues, submitText }: Props) => {
  const { t } = useTranslation()
  const isAdmin = useIsUserAdmin()

  const form = useForm<BasicProfileSettingsFormValues>({
    defaultValues,
  })
  const storedSearch = form.getValues('stored_search')

  return (
    <FormProvider {...form}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-4">
          <FormField
            name="name"
            rules={{
              required: t('This field is required'),
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>Profile name</Trans>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder={t('Profile name')} autoComplete="off" data-1p-ignore />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {isAdmin && <ProfileFolderFormInput />}

          <ProfileParentFormField />

          <FormField
            name="trend_interval"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>
                  <Trans>Trend interval</Trans>
                </FormLabel>
                <Select
                  value={String(field.value)}
                  onChange={(value) => {
                    field.onChange(parseInt(value, 10))
                  }}
                >
                  <FormControl>
                    <SelectTrigger
                      displayValue={(selectedValue) => findLabelToValue(selectedValue)}
                      placeholder={t('Choose trend interval')}
                      defaultValue={String(intervalOptions[0].value)}
                    />
                  </FormControl>
                  <SelectContent>
                    {intervalOptions.map(({ value, label }) => (
                      <SelectItem key={value} value={String(value)}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          {storedSearch && (
            <FormField
              name="stored_search.active"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Switch
                      checked={field.value === 1}
                      onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    />
                  </FormControl>
                  <FormLabel>
                    <Trans>Stored search</Trans>: {storedSearch.id}
                  </FormLabel>
                </FormItem>
              )}
            />
          )}

          {isAdmin && (
            <FormField
              name="blessed"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>
                    <Trans>Enable Danish content</Trans>
                  </FormLabel>
                </FormItem>
              )}
            />
          )}

          <Button variant="success" loading={form.formState.isSubmitting} type="submit" className="mt-2 w-full">
            <Save24Regular aria-hidden />
            {submitText ?? <Trans>Save</Trans>}
          </Button>
        </form>
      </Form>
    </FormProvider>
  )
}
