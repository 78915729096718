import { FC } from 'react'
import * as Sentry from '@sentry/react'

import { Home16Regular } from '@fluentui/react-icons'
import { Trans } from 'react-i18next'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { AlertsDetailBreadcrumb } from './components/alerts/AlertsTable/AlertsDetailBreadcrumb.tsx'
import RequireAuthentication from './components/auth/RequireAuthentication'
import SuspenseWithLoading from './components/common/Modal/SuspenseWithLoading'
import { UserManagementActionBar } from './components/layout/actionBar/userManagement/UserManagementActionBar.tsx'
import { UserDetailBreadcrumb } from './components/userManagement/UserDetail/UserDetailBreadcrumb'
import UserManagementView from './components/userManagement/UserManagementView/index.tsx'
import { lazy } from './helpers/lazy'
import AlertsPage from './pages/alerts/AlertsPage.tsx'
import { default as App } from './pages/app/App'
import CustomerViewPage from './pages/customer_view/CustomerViewPage.tsx'
import SingleArticleView from './pages/article/SingleArticleView'
import ProfilesPage from './pages/profiles/ProfilesPage.tsx'
import FeedRootPage from './pages/feeds/FeedRootPage.tsx'
import FeedDetailBreadcrumb from './components/feeds/FeedDetailBreadcrumb.tsx'
import { NavigationSectionKey } from './helpers/navigation.tsx'

const AgreementsRootPage = lazy(() => import('./pages/agreements/AgreementsRootPage.tsx'))
const AgreementsOverviewPage = lazy(() => import('./pages/agreements/AgreementsOverview.tsx'))
const Agreement = lazy(() => import('./pages/agreements/Agreement'))
const AlertsDashboard = lazy(() => import('./components/alerts/AlertsDashboard.tsx'))
const SearchPage = lazy(() => import('./pages/search/SearchPage'))
const EditProfilePage = lazy(() => import('./pages/profiles/EditProfilePage'))
const ArticleView = lazy(() => import('./pages/article/ArticleView'))
const MobileView = lazy(() => import('./pages/mobile/MobileView'))
const AddAlert = lazy(() => import('./components/alerts/AddAlert'))
const EditAlert = lazy(() => import('./components/alerts/EditAlert'))
const StatisticsPage = lazy(() => import('./pages/statistics/StatisticsPage'))
const Statistics = lazy(() => import('./components/statistics'))
const StatisticsComparison = lazy(() => import('./components/statistics/statisticsComparison'))
const ProfileEditor = lazy(() => import('./components/profile/ProfileEditor'))
const ViewAlert = lazy(() => import('./components/alerts/ViewAlert'))
const CreateFeedPage = lazy(() => import('./pages/feeds/CreateFeedPage').then((m) => ({ default: m.CreateFeedPage })))
const EditFeedPage = lazy(() => import('./pages/feeds/EditFeedPage').then((m) => ({ default: m.EditFeedPage })))
const FeedOverviewPage = lazy(() => import('./pages/feeds/FeedOverviewPage'))
const AlertMain = lazy(() => import('./components/alerts/AlertMain'))
const FrontPagesView = lazy(() => import('./pages/frontpages/FrontPagesView'))
const UserManagement = lazy(() => import('./components/userManagement'))
const UserDetail = lazy(() => import('./components/userManagement/UserDetail'))
const CustomerView = lazy(() => import('./components/impersonation/CustomerView'))
const FoldersPage = lazy(() => import('./pages/folders'))
const CallbackPage = lazy(() => import('./pages/Callback'))
const LoggedOutPage = lazy(() => import('./pages/logged-out'))
const MobileArticle = lazy(() => import('./components/mobile/MobileArticle'))
const MobileListPage = lazy(() => import('./pages/mobile/list/MobileListPage'))
const NotFoundErrorPage = lazy(() => import('./pages/errors/NotFoundErrorPage'))
const MobileAlertsPage = lazy(() => import('./pages/mobile/alerts/MobileAlertsPage'))
const MobileAlertHistory = lazy(() => import('./components/mobile/MobileAlertHistory'))
const MobileAlertContent = lazy(() => import('./components/mobile/MobileAlertContent'))

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    handle: {
      crumb: <Home16Regular />,
    },
    element: <RequireAuthentication as={App} />,
    children: [
      // search - profiles, trash, edit alert content
      {
        path: 'search',
        element: (
          <SuspenseWithLoading>
            <SearchPage />
          </SuspenseWithLoading>
        ),
        children: [
          {
            path: 'new',
            element: (
              <SuspenseWithLoading>
                <ProfileEditor />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'edit',
            element: (
              <SuspenseWithLoading>
                <ProfileEditor />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'article',
            element: (
              <SuspenseWithLoading>
                <SingleArticleView />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'profiles',
        element: <ProfilesPage />,
        children: [
          {
            path: ':profileId',
            element: (
              <SuspenseWithLoading>
                <EditProfilePage />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'feeds',
        element: <FeedRootPage />,
        handle: {
          crumb: <Trans>Feeds</Trans>,
        },
        children: [
          {
            index: true,
            element: (
              <SuspenseWithLoading>
                <FeedOverviewPage />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'new',
            handle: {
              crumb: <Trans>Create new feed</Trans>,
            },
            element: (
              <SuspenseWithLoading>
                <CreateFeedPage />
              </SuspenseWithLoading>
            ),
          },
          {
            path: ':feedId',
            handle: {
              dynamic: true,
              crumb: <FeedDetailBreadcrumb />,
            },
            element: (
              <SuspenseWithLoading>
                <EditFeedPage />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'statistics',
        element: (
          <SuspenseWithLoading>
            <StatisticsPage />
          </SuspenseWithLoading>
        ),
        children: [
          {
            index: true,
            element: (
              <SuspenseWithLoading>
                <Statistics />
              </SuspenseWithLoading>
            ),
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: (
                  <SuspenseWithLoading>
                    <Statistics />
                  </SuspenseWithLoading>
                ),
              },
              {
                path: 'compare',
                element: (
                  <SuspenseWithLoading>
                    <StatisticsComparison />
                  </SuspenseWithLoading>
                ),
              },
            ],
          },
          {
            path: 'compare',
            element: (
              <SuspenseWithLoading>
                <StatisticsComparison />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'alerts',
        handle: {
          crumb: <Trans>Alerts</Trans>,
        },
        element: <AlertsPage />,
        children: [
          {
            index: true,
            element: (
              <SuspenseWithLoading>
                <AlertsDashboard />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'new',
            handle: {
              crumb: <Trans>Create new alert</Trans>,
            },
            element: (
              <SuspenseWithLoading>
                <AddAlert />
              </SuspenseWithLoading>
            ),
          },
          {
            path: ':alertId',
            handle: {
              dynamic: true,
              crumb: <AlertsDetailBreadcrumb />,
            },
            children: [
              {
                index: true,
                element: (
                  <SuspenseWithLoading>
                    <AlertMain />
                  </SuspenseWithLoading>
                ),
              },
              {
                path: 'edit',
                element: (
                  <SuspenseWithLoading>
                    <EditAlert />
                  </SuspenseWithLoading>
                ),
                handle: {
                  crumb: <Trans>Edit alert</Trans>,
                },
              },
              {
                path: ':mailLogId',
                children: [
                  {
                    index: true,
                    element: (
                      <SuspenseWithLoading>
                        <AlertMain />
                      </SuspenseWithLoading>
                    ),
                  },
                  {
                    path: 'view',
                    element: (
                      <SuspenseWithLoading>
                        <ViewAlert />
                      </SuspenseWithLoading>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'frontpages',
        element: (
          <SuspenseWithLoading>
            <FrontPagesView />
          </SuspenseWithLoading>
        ),
      },
      {
        path: 'user-management',
        handle: {
          crumb: <Trans>User management</Trans>,
        },
        element: <UserManagement />,
        children: [
          {
            index: true,
            element: <Navigate to="/user-management/all" />,
          },
          {
            path: ':tab',
            element: (
              <SuspenseWithLoading>
                <UserManagementActionBar />
                <UserManagementView />
              </SuspenseWithLoading>
            ),
          },
          {
            path: 'user/:userId',
            handle: {
              crumb: <UserDetailBreadcrumb />,
              dynamic: true,
            },
            element: (
              <SuspenseWithLoading>
                <UserDetail />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: NavigationSectionKey.AGREEMENTS,
        handle: {
          crumb: <Trans>Agreements</Trans>,
        },
        element: <AgreementsRootPage />,
        children: [
          {
            index: true,
            element: (
              <SuspenseWithLoading>
                <AgreementsOverviewPage />
              </SuspenseWithLoading>
            ),
          },
          {
            path: ':agreementId',
            handle: {
              dynamic: true,
              crumb: <Trans>Agreement</Trans>,
            },
            element: (
              <SuspenseWithLoading>
                <Agreement />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'customer_view',
        handle: {
          crumb: <Trans>Customer view</Trans>,
        },
        element: <CustomerViewPage />,
        children: [
          {
            index: true,
            element: (
              <SuspenseWithLoading>
                <CustomerView />
              </SuspenseWithLoading>
            ),
          },
        ],
      },
      {
        path: 'folders',
        element: (
          <SuspenseWithLoading>
            <FoldersPage />
          </SuspenseWithLoading>
        ),
      },
      {
        path: '*',
        element: <SuspenseWithLoading>{<NotFoundErrorPage />}</SuspenseWithLoading>,
      },
    ],
  },
  {
    path: 'article',
    element: (
      <SuspenseWithLoading>
        <RequireAuthentication as={ArticleView} />
      </SuspenseWithLoading>
    ),
  },
  {
    path: 'mobile',
    element: (
      <SuspenseWithLoading>
        <RequireAuthentication as={MobileView} />
      </SuspenseWithLoading>
    ),
    children: [
      { path: 'article', element: <MobileArticle /> },
      { path: 'search', element: <MobileArticle /> },
      { path: 'list', element: <MobileListPage /> },
      {
        path: 'alerts',
        children: [
          {
            index: true,
            element: <MobileAlertsPage />,
          },
          {
            path: ':alertId',
            children: [
              {
                index: true,
                element: <MobileAlertHistory />,
              },
              {
                path: ':mailLogId',
                element: <MobileAlertContent />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'callback',
    element: (
      <SuspenseWithLoading>
        <CallbackPage />
      </SuspenseWithLoading>
    ),
  },
  {
    path: 'logged-out',
    element: (
      <SuspenseWithLoading>
        <LoggedOutPage />
      </SuspenseWithLoading>
    ),
  },
  {
    path: '*',
    element: (
      <SuspenseWithLoading>
        <NotFoundErrorPage />
      </SuspenseWithLoading>
    ),
  },
])

const Routes: FC = () => <RouterProvider router={router} />

export default Routes
