import { CheckedState } from '@radix-ui/react-checkbox'

export enum LicenseMode {
  NORMAL = 'normal',
  ENFORCE = 'enforce',
}

export type LicenseOptions = {
  license: number
  licenseShare: number
  licenseMail: number
  licenseReport: number
  licenseEnforce: number
  licenseEnforceShare: number
  licenseEnforceMail: number
  licenseEnforceReport: number
}

export type LicenseTableRowData = {
  name: string
  value: number
  searchSetting: ToggleOptions
  reportSetting: ToggleOptions
  mailSetting: ToggleOptions
  shareSetting: ToggleOptions
  allCheckBox: CheckedState
}

export type FollowLicense = {
  type: boolean
  numberOfSentences: boolean
  maxPercentageOfCharactersToShow: boolean
  maxNumberOfWordsToShow: boolean
}

export type SummarizerParamFieldTypeFrontend = {
  label: string
  value: number
}

export type SummarizerParamFieldFrontend = {
  type: SummarizerParamFieldTypeFrontend | null
  numberOfSentences?: string
  maxPercentageOfCharactersToShow?: string
  maxNumberOfWordsToShow?: string
}

// Frontend needs a modified Summarizer parameter
export type SummarizerParamFrontend = {
  searchSettings: SummarizerParamFieldFrontend
  reportSettings: SummarizerParamFieldFrontend
  mailSettings: SummarizerParamFieldFrontend
  shareSettings: SummarizerParamFieldFrontend
  followLicense: FollowLicense
}

export type Age = {
  value: number | null
  timeFrame?: TimeFrameValues
}

export type AgeRestrictionOptions = {
  maxArchiveAge: Age
  maxFreeSearchAge: Age
}

export const enum SummarizerTableRowId {
  Type = 'type',
  NumberOfSentences = 'numberOfSentences',
  MaxPercentage = 'maxPercentageOfCharactersToShow',
  MaxNumberOfWords = 'maxNumberOfWordsToShow',
}

export const enum TimeFrameValues {
  Seconds = 'Seconds',
  Days = 'Days',
  Months = 'Months',
  Years = 'Years',
}

export enum ToggleOptions {
  Checked = 'checked',
  Enforced = 'enforced',
  Unchecked = 'unchecked',
}

export enum LicenseType {
  SearchSetting = 'searchSetting',
  ReportSetting = 'reportSetting',
  MailSetting = 'mailSetting',
  ShareSetting = 'shareSetting',
}
