/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  FolderSortRequestBody,
  FolderView,
  FolderViewCreate,
  FoldersSharesTargetsSearchListParams,
  OpointUser,
} from '../opoint.schemas'
import { useCustomAxiosInstance } from '.././custom-client'
import type { ErrorType } from '.././custom-client'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

/**
 * Lists available folders.

We have default folders of each type that should always be available to
everyone. They are not necessarily present in the db, so we fake the
existence in that case.
 * @summary List available folders
 */
export const useFoldersListHook = () => {
  const foldersList = useCustomAxiosInstance<FolderView[]>()

  return useCallback(
    (signal?: AbortSignal) => {
      return foldersList({ url: `/folders/`, method: 'GET', signal })
    },
    [foldersList],
  )
}

export const getFoldersListQueryKey = () => {
  return [`/folders/`] as const
}

export const useFoldersListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFoldersListHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFoldersListHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFoldersListQueryKey()

  const foldersList = useFoldersListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFoldersListHook>>>> = ({ signal }) =>
    foldersList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FoldersListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFoldersListHook>>>>
export type FoldersListQueryError = ErrorType<unknown>

/**
 * @summary List available folders
 */
export const useFoldersList = <
  TData = Awaited<ReturnType<ReturnType<typeof useFoldersListHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFoldersListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFoldersListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Create a Folder view
 */
export const useFoldersCreateHook = () => {
  const foldersCreate = useCustomAxiosInstance<FolderViewCreate>()

  return useCallback(
    (folderViewCreate: NonReadonly<FolderViewCreate>) => {
      return foldersCreate({
        url: `/folders/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: folderViewCreate,
      })
    },
    [foldersCreate],
  )
}

export const useFoldersCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersCreateHook>>>,
    TError,
    { data: NonReadonly<FolderViewCreate> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFoldersCreateHook>>>,
  TError,
  { data: NonReadonly<FolderViewCreate> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const foldersCreate = useFoldersCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFoldersCreateHook>>>,
    { data: NonReadonly<FolderViewCreate> }
  > = (props) => {
    const { data } = props ?? {}

    return foldersCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type FoldersCreateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFoldersCreateHook>>>>
export type FoldersCreateMutationBody = NonReadonly<FolderViewCreate>
export type FoldersCreateMutationError = ErrorType<unknown>

/**
 * @summary Create a Folder view
 */
export const useFoldersCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersCreateHook>>>,
    TError,
    { data: NonReadonly<FolderViewCreate> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useFoldersCreateHook>>>,
  TError,
  { data: NonReadonly<FolderViewCreate> },
  TContext
> => {
  const mutationOptions = useFoldersCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useFoldersRetrieveHook = () => {
  const foldersRetrieve = useCustomAxiosInstance<void>()

  return useCallback(
    (folderId: number, signal?: AbortSignal) => {
      return foldersRetrieve({ url: `/folders/${folderId}/`, method: 'GET', signal })
    },
    [foldersRetrieve],
  )
}

export const getFoldersRetrieveQueryKey = (folderId: number) => {
  return [`/folders/${folderId}/`] as const
}

export const useFoldersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFoldersRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  folderId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFoldersRetrieveHook>>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFoldersRetrieveQueryKey(folderId)

  const foldersRetrieve = useFoldersRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFoldersRetrieveHook>>>> = ({ signal }) =>
    foldersRetrieve(folderId, signal)

  return { queryKey, queryFn, enabled: !!folderId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FoldersRetrieveQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFoldersRetrieveHook>>>>
export type FoldersRetrieveQueryError = ErrorType<unknown>

export const useFoldersRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useFoldersRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  folderId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFoldersRetrieveHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFoldersRetrieveQueryOptions(folderId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useFoldersUpdateHook = () => {
  const foldersUpdate = useCustomAxiosInstance<void>()

  return useCallback(
    (folderId: number) => {
      return foldersUpdate({ url: `/folders/${folderId}/`, method: 'PUT' })
    },
    [foldersUpdate],
  )
}

export const useFoldersUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersUpdateHook>>>,
    TError,
    { folderId: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFoldersUpdateHook>>>,
  TError,
  { folderId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const foldersUpdate = useFoldersUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFoldersUpdateHook>>>,
    { folderId: number }
  > = (props) => {
    const { folderId } = props ?? {}

    return foldersUpdate(folderId)
  }

  return { mutationFn, ...mutationOptions }
}

export type FoldersUpdateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFoldersUpdateHook>>>>

export type FoldersUpdateMutationError = ErrorType<unknown>

export const useFoldersUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersUpdateHook>>>,
    TError,
    { folderId: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useFoldersUpdateHook>>>,
  TError,
  { folderId: number },
  TContext
> => {
  const mutationOptions = useFoldersUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useFoldersPartialUpdateHook = () => {
  const foldersPartialUpdate = useCustomAxiosInstance<void>()

  return useCallback(
    (folderId: number) => {
      return foldersPartialUpdate({ url: `/folders/${folderId}/`, method: 'PATCH' })
    },
    [foldersPartialUpdate],
  )
}

export const useFoldersPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersPartialUpdateHook>>>,
    TError,
    { folderId: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFoldersPartialUpdateHook>>>,
  TError,
  { folderId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const foldersPartialUpdate = useFoldersPartialUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFoldersPartialUpdateHook>>>,
    { folderId: number }
  > = (props) => {
    const { folderId } = props ?? {}

    return foldersPartialUpdate(folderId)
  }

  return { mutationFn, ...mutationOptions }
}

export type FoldersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFoldersPartialUpdateHook>>>
>

export type FoldersPartialUpdateMutationError = ErrorType<unknown>

export const useFoldersPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersPartialUpdateHook>>>,
    TError,
    { folderId: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useFoldersPartialUpdateHook>>>,
  TError,
  { folderId: number },
  TContext
> => {
  const mutationOptions = useFoldersPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useFoldersDestroyHook = () => {
  const foldersDestroy = useCustomAxiosInstance<void>()

  return useCallback(
    (folderId: number) => {
      return foldersDestroy({ url: `/folders/${folderId}/`, method: 'DELETE' })
    },
    [foldersDestroy],
  )
}

export const useFoldersDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersDestroyHook>>>,
    TError,
    { folderId: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFoldersDestroyHook>>>,
  TError,
  { folderId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const foldersDestroy = useFoldersDestroyHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFoldersDestroyHook>>>,
    { folderId: number }
  > = (props) => {
    const { folderId } = props ?? {}

    return foldersDestroy(folderId)
  }

  return { mutationFn, ...mutationOptions }
}

export type FoldersDestroyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFoldersDestroyHook>>>>

export type FoldersDestroyMutationError = ErrorType<unknown>

export const useFoldersDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersDestroyHook>>>,
    TError,
    { folderId: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useFoldersDestroyHook>>>,
  TError,
  { folderId: number },
  TContext
> => {
  const mutationOptions = useFoldersDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Lists users that could be shared.

This endpoint is considered experimental, and will likely be replaced
in the longer term with a standardized /user/search/ accross all kinds
of sharing.
 */
export const useFoldersSharesTargetsSearchListHook = () => {
  const foldersSharesTargetsSearchList = useCustomAxiosInstance<OpointUser[]>()

  return useCallback(
    (params: FoldersSharesTargetsSearchListParams, signal?: AbortSignal) => {
      return foldersSharesTargetsSearchList({ url: `/folders/shares/targets/search/`, method: 'GET', params, signal })
    },
    [foldersSharesTargetsSearchList],
  )
}

export const getFoldersSharesTargetsSearchListQueryKey = (params: FoldersSharesTargetsSearchListParams) => {
  return [`/folders/shares/targets/search/`, ...(params ? [params] : [])] as const
}

export const useFoldersSharesTargetsSearchListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFoldersSharesTargetsSearchListHook>>>,
  TError = ErrorType<unknown>,
>(
  params: FoldersSharesTargetsSearchListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFoldersSharesTargetsSearchListHook>>>,
      TError,
      TData
    >
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFoldersSharesTargetsSearchListQueryKey(params)

  const foldersSharesTargetsSearchList = useFoldersSharesTargetsSearchListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFoldersSharesTargetsSearchListHook>>>> = ({
    signal,
  }) => foldersSharesTargetsSearchList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersSharesTargetsSearchListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FoldersSharesTargetsSearchListQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFoldersSharesTargetsSearchListHook>>>
>
export type FoldersSharesTargetsSearchListQueryError = ErrorType<unknown>

export const useFoldersSharesTargetsSearchList = <
  TData = Awaited<ReturnType<ReturnType<typeof useFoldersSharesTargetsSearchListHook>>>,
  TError = ErrorType<unknown>,
>(
  params: FoldersSharesTargetsSearchListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFoldersSharesTargetsSearchListHook>>>,
      TError,
      TData
    >
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFoldersSharesTargetsSearchListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Sort folders.

Sort folders that we own or have access to.
 */
export const useFoldersSortCreateHook = () => {
  const foldersSortCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (folderSortRequestBody: FolderSortRequestBody) => {
      return foldersSortCreate({
        url: `/folders/sort/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: folderSortRequestBody,
      })
    },
    [foldersSortCreate],
  )
}

export const useFoldersSortCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersSortCreateHook>>>,
    TError,
    { data: FolderSortRequestBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFoldersSortCreateHook>>>,
  TError,
  { data: FolderSortRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const foldersSortCreate = useFoldersSortCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFoldersSortCreateHook>>>,
    { data: FolderSortRequestBody }
  > = (props) => {
    const { data } = props ?? {}

    return foldersSortCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type FoldersSortCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFoldersSortCreateHook>>>
>
export type FoldersSortCreateMutationBody = FolderSortRequestBody
export type FoldersSortCreateMutationError = ErrorType<unknown>

export const useFoldersSortCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFoldersSortCreateHook>>>,
    TError,
    { data: FolderSortRequestBody },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useFoldersSortCreateHook>>>,
  TError,
  { data: FolderSortRequestBody },
  TContext
> => {
  const mutationOptions = useFoldersSortCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
