import { Outlet, useNavigate } from 'react-router-dom'
import { Button, cn, Popover, PopoverContent, PopoverTrigger } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import { ArrowLeft16Regular, Edit16Filled } from '@fluentui/react-icons'
import Header from '../../components/topNav/Header'
import { SearchFilterKey } from '../../components/hooks/useSearchFilters.ts'
import useSearchRouteBuilder from '../../components/hooks/useSearchRouteBuilder.ts'
import { useProfilesRetrieve } from '../../api/profiles/profiles.ts'
import { useRequiredParams } from '../../components/hooks/useRequiredParams.ts'
import { IntegratedErrorBoundary } from '../../components/common/IntegratedErrorBoundary.tsx'
import { EditBasicProfileSettings } from '../../components/profile/basic-settings/EditBasicProfileSettings.tsx'

const ProfilesPage = () => {
  const navigate = useNavigate()
  const { profileId } = useRequiredParams(['profileId'])
  const { getSearchRoute } = useSearchRouteBuilder()
  const { data: profileData } = useProfilesRetrieve(+profileId, { with_query: true })

  const handleExit = () => {
    navigate(getSearchRoute(SearchFilterKey.PROFILES, profileId!))
  }

  return (
    <>
      <Header>
        <div className="flex items-center">
          <div className={cn({ 'border-r border-grey.6': profileData?.name })}>
            <Button variant="text" className="h-fit w-max gap-2 text-sky.1 no-underline" onClick={handleExit}>
              <ArrowLeft16Regular /> <Trans>Exit</Trans>
            </Button>
          </div>
          {profileData?.name ? (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="text" className="font-bold text-sky.1 no-underline">
                  {profileData.name}
                  <Edit16Filled aria-hidden />
                </Button>
              </PopoverTrigger>
              <PopoverContent side="bottom" className="w-80" align="start">
                <IntegratedErrorBoundary>
                  <EditBasicProfileSettings profileId={+profileId} />
                </IntegratedErrorBoundary>
              </PopoverContent>
            </Popover>
          ) : (
            <div className="h-full w-36 animate-pulse rounded-sm bg-grey.7" />
          )}
        </div>
      </Header>
      <Outlet />
    </>
  )
}

export default ProfilesPage
