import { ProfileWithChildren } from '../../types/profile.ts'

type ProfileWithLevels = {
  id?: number
  name: string
  level: number
}

export const flattenOptions = (profiles: ProfileWithChildren[], level = 0): ProfileWithLevels[] => {
  return profiles.reduce<ProfileWithLevels[]>((acc, profile) => {
    acc.push({
      id: profile.id,
      name: profile.name,
      level: level,
    })
    if (profile.children) {
      acc.push(...flattenOptions(profile.children, level + 1))
    }
    return acc
  }, [])
}
